/* Empty Cart Window */
.empty_cart_block{
    display: flex;
    flex-direction: column;
    height: 300px;
    justify-content: center;
    align-items: center;
}
.text_empty_cart{
    color: #004787;
    font-size: 16px;
    font-weight: 500;
    margin: 16px 0;
}
.text_add_items{
    color: #A9A9A9;
    font-size: 16px;
    font-weight: 500;
    text-align: center;
}