.popup__layout{
	position: absolute;
	z-index: 3;

	background: white;

	left: 0;
	right: 0;
	margin: 0 auto;
	top: 70px;
	bottom: 100px;
	
	color: #333;
	cursor: initial;
	overflow: overlay;
	overscroll-behavior: contain;

    width: 100%;
	max-width: 600px;
	border-radius: 30px;

	padding: 25px;
	box-sizing: border-box;
}
.header_wrapper{
	padding-bottom: 30px;
}
.header_container{
	display: flex;
	justify-content: space-between;
}
.header_title{
	color: #0060B7;
	font-size: 24px;
	font-weight: 700;
}


/* FORM */

.input__field{
	position: relative;
	width: 100%;
}
.input__field_mobile{
	margin: 30px 0 15px 0;
}
  .label {
	position: absolute;
	top: 0;
	left: 15px;
	width: 100%;
	color: #d3d3d3;
	transition: 0.2s all;
	cursor: text;
	height: 100%;
	display: flex;
	align-items: center;
	font-size: 16px;
  }
  input {
	width: 100%;
	border: 0;
	outline: 0;
	padding: 15px 30px;
	color: #000;
	font-size: 16px;
	border-radius: 16px;
	border: 1px solid #AFAFAF;
	box-sizing: border-box;
	border-color: #AFAFAF;
	margin-top: 2px;
  }
  /* input:invalid {
	outline: 0;
	color: #ff2300;
	border-color: #ff2300;
  } */
  /* input:focus,
  input:valid {
	border-color: #0060B7;
  } */
   input:focus~.label,
  input:valid~.label {
	left: 0;
	top: -35px;
	font-size: 18px;
	font-weight: 600;
  }

  input:valid~.label,
  input:focus~.label{
	color: #0060B7;
  }
  /* input:valid~.label{
	color: #00dd22;
  } */
  /* input:invalid~.label{
	color: #ff2300;
  } */

.input_comment{
	height: 150px;
	width: 100%;
	border-radius: 16px;
	padding: 15px 30px;
	font-size: 16px;
	box-sizing: border-box;
	border: 1px solid #AFAFAF;
	margin-bottom: 15px;
	outline: none;
}
.comment_label{
	color: #0060B7;
	font-size: 18px;
	font-weight: 600;
	margin-bottom: 2px;
	display: flex;
}
.uploadBlock{
	display: flex;
	justify-content: center;
	flex-direction: column;
	align-items: center;
	border: 2px #ebe9e9 dashed;
	padding: 30px 0;
	height: 130px;
	box-sizing: border-box;
}
.attach_file_block{
	display: flex;
	align-items: center;
	justify-content: center;
}
.attach_file_text{
	color:#1F85C5;
	font-size: 16px;
	font-weight: 400;
	text-decoration-line: underline;
}

/*  */
.btn_container{
    text-align: center;
}
.btn_close{
    border-radius: 16px;
    background: #0060B7;
    color: #fff;
    padding: 10px 50px;
    font-size: 16px;
    margin: 20px 0 10px;
}
.btn_close:disabled{
	background: #989898;
}
.msgError{
	color: red;
}

@media only screen and (max-width: 550px){
	.popup__layout{
		border-radius: 30px 30px 0 0;
		height: calc(100% - 70px);
		bottom: 0;
	}
	.btn_container{
		margin-bottom: 40px;
	}
}