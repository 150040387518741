.wrapper{
    display: flex;
    width: calc(100% - 60px);
}
.card__wrapper{
    margin-bottom: 20px;
    width: 100%;
}
.content_block img{
    float: right;
    margin: 0 0 30px 50px;
    max-width: 500px;
    border-radius: 16px;
}
.content_block p{
    margin-bottom: 20px;
    line-height: 2;
}

@media only screen and (max-width: 820px){
    .content_block img{
        max-width: 250px;
    }
}
@media only screen and (max-width: 650px){
    .content_block img{
        max-width: 200px;
    }
}
@media only screen and (max-width: 550px){
    .wrapper{
        width: calc(100% - 30px);
    }
    .content_block img{
        float: none;
        width: 100%;
        max-width: initial;
        box-sizing: border-box;
        margin: 0 0 10px 0;
    }
}