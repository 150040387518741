.popup__layout{
	position: absolute;
	z-index: 3;

	background: white;

	left: 0; 
    right: 0; 
    margin: 0 auto;
    top: 120px;

	overflow: overlay;
	overscroll-behavior: contain;

	color: #333;
	cursor: initial;

	width: 95%;
    max-width: 500px;
	border-radius: 30px;

	padding: 25px;
	box-sizing: border-box;
}
.header_container{
    display: flex;
    justify-content: space-between;
}
.header_title{
    font-size: 20px;
    color: #0060B7;
}
.login__container{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 420px;
    margin: 0 auto;
    text-align: center;
}

.message{
    color: #000;
    font-size: 16px;
    font-weight: 500;
    padding: 20px 0 30px;
}
.btn_return{
    padding: 10px 50px;
    border-radius: 16px;
    border: 1px solid #0060B7;
    font-weight: 600;
    color: #0060B7;
    margin-bottom: 30px;
}