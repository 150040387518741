.card_wrapper{
    width: 100%;
    margin-bottom: 20px;
}
.section_block{
    width: 100%;    
}
.title_block{
    color: #000;
    font-size: 20px;
    font-weight: 600;
    padding: 8px 0 16px 10px;
}


/* selector */
.RadioGroupRoot{
    display: flex;
}
.card__content{
    box-sizing: border-box;
    padding: 0;
    margin-right: 15px;
}
/* Radio Buttons */
.itemsContainer,
.radioGroupSelectorContainer{
    box-shadow: 3px -1px 19.5px 0px rgba(0, 96, 183, 0.25);
    border-radius: 16px;
    
}
.itemsContainer{
    padding: 16px 10px 10px 5px;
    margin-bottom: 5px;
}
.radioGroupSelectorContainer{
    margin: 15px 0;
    padding: 16px;
}
.radioGroupBlock{
    display: flex;
    padding: 20px;
}
.radioGroupItem{
    background-color: white;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 10px;
    padding: 0;
    border: 1px solid #0060B7;
    margin-right: 10px;
}
.radioGroupItem[disabled]{
    border: 1px solid #c8c8c8;
}
.radioGroupItem[disabled] ~ .label{
    color: #c8c8c8;
}
.radioGroupIndicator{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    position: relative;
}
.radioGroupIndicator:after{
    content: "";
    display: block;
    width: 80%;
    height: 80%;
    border-radius: 50%;
    background-color: #0060B7;
}
.label{
    display: flex;
    cursor: pointer;
    flex-direction: column;
    align-items: center;
}
/* end of radio buttons */

@media only screen and (max-width: 850px){
    .RadioGroupRoot{
        flex-direction: column;
    }
    .card__content{
        margin-bottom: 10px;
        width: 100%;
    }
}