/* MAIN */
.main__banner{
    height: 100vh;
    width: 100%;
    position: relative;

    background-image:   url('../../assets/img/logo/logo_with_slogan.svg'),
                        url('../../assets/img/by_page/main/products.png?v=2.00'), 
                        url('../../assets/img/by_page/main/splash.svg');
    background-size: 30%, 75%, 200%;
    background-repeat: no-repeat;
    background-position: 20% 10%, center 70px, 0;
}

.banner__content{
    text-align: center;
    bottom: 200px;
    position: absolute;
    margin: 0 auto;
    width: calc( 100% - 60px );
    left: 0;
    right: 0;
}

@media only screen and (max-width: 1024px){
    .main__banner{
        background-size: 30%, 90%, 200%;
    }
}
@media only screen and (max-width: 900px){
    .main__banner{
        background-size: 30%, 120%, 200%;
        background-position: 20% 10%, 0% 70px, 0;
    }
}
@media only screen and (max-width: 768px){
    .main__banner{
        background-size: 40%, 140%, 200%;
        background-position: 15% 5%, 50% 70%, 0;
    }
}
@media only screen and (max-width: 650px){
    .main__banner{
        background-size: 45%, 160%, 200%;
        background-position: 15% 5%, 55% 30%, 0;
    }
}
@media only screen and (max-width: 550px){
    .main__banner{
        background-size: 50%, 200%, 200%;
        background-position: 15% 5%, 70% 30%, 0;
    }
}
@media only screen and (max-width: 500px){
    .main__banner{
        background-size: 55%, 100%, 200%;
        background-position: 15% 5%, 70% 30%, 0;
    }
}


