.popup__layout{
	position: absolute;
	z-index: 3;

	background: white;

    left: 0;
    right: 0;
	margin: 0 auto;
	overflow: overlay;
	overscroll-behavior: contain;

	color: #333;
	cursor: initial;

	width: 95%;
	max-width: 900px;
	height: calc(100% - 110px);
    max-height: 740px;
	border-radius: 30px;

	padding: 10px;
	box-sizing: border-box;
    top: 50%;
    transform: translateY(-50%);
}
.card__wrapper{
    height: 100%;
    width: 100%;
    padding: 16px 4px;
    margin: 0;
}
/* Header */
.header_container{
    display: flex;
    justify-content: space-between;
    padding: 0px 15px 15px 15px;
}
.logo{
    width: 120px;
    height: 100%;
}
/* Body */
.contacts__container{
    display: flex;
    justify-content: space-between;
}
.left_part,
.right_part{
    width: 48%;
}
/* Left */
.schedule_social_container{
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    padding: 10px;
}
.schedule_container{
    width: 60%;
}
.social_container{
    width: 40%;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.btn_telegram,
.btn_whatsapp{
    padding: 5px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    font-weight: 600;
    border-radius: 16px;
    min-width: 135px;
    width: 100%;
}
.btn_telegram img,
.btn_whatsapp img{
    width: 24px;
    height: 24px;
    margin-right: 10px;
}
.btn_telegram{
    color: #007FC9;
    border: 1px solid #007FC9;
    margin-bottom: 10px;
}
.btn_whatsapp{
    color: #32B342;
    border: 1px solid #32B342;
}
.contact_container{
    border-radius: 16px;
    border: 1px solid #0060B7;
    padding-right: 10px;
    height: 35px;
    display: flex;
    align-items: center;
    margin-bottom: 8px;
}
.contact_label{
    color: #fff;
    font-size: 15px;
    font-weight: 500;
    border-radius: 16px;
    background: #0060B7;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 130px;
}
.contact_field{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}
.contact_svg_phone path{
    fill: #0060B7;
}
.contact_svg_email path{
    stroke: #0060B7;
}
.contact_email,
.contact_mobile{
    color: #0060B7;
}

/* Right */
.phone_container,
.email_container,
.address_container,
.map_container{
    border-radius: 16px;
    box-shadow: 0px 0px 10px 0px rgba(0, 96, 183, 0.25);
    margin-bottom: 25px;
    padding: 10px;
}
.map{
    width: 100%;
    height: 100%;
    box-sizing: border-box;
}
.address_container{

}
.schedule_title,
.phone_title,
.email_title,
.address_title{
    color: #0060B7;
    font-size: 22px;
    font-weight: 700;
    margin-bottom: 8px;
}
.day_of_the_week_label{
    width: 55px;
    display: inline-block;
}
.day_of_the_week_text{
    font-weight: 500;
}
.address_address{
    color: #0060B7;
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 12px;
}
.address_btn_block{
    display: flex;
    justify-content: space-between;
}
.address_btn{
    color: #0060B7;
    border-radius: 16px;
    border: 1px solid #0060B7;
    width: 32%;
    padding: 10px 0;
    font-size: 16px;
    font-weight: 500;
    display: flex;
    justify-content: center;
    align-items: center;
}
.address_btn img{
    width: 20px;
    height: 20px;
    margin-right: 10px;
}

/* Media */
/* @media only screen and (max-width: 850px){
    .popup__layout{
        padding: 0;
    }
} */
@media only screen and (max-width: 960px){
    .popup__layout{
        top: auto;
        bottom: 0;
        transform: none;
        border-radius: 30px 30px 0 0;
        width: 70%;
        height: calc(100% - 110px);
        max-height: none;
    }
    .left_part,
    .right_part{
        width: 100%;
    }
    .contacts__container{
        flex-direction: column;
    }
    .card__wrapper{
        box-shadow: none;
        -webkit-backdrop-filter:none;
        backdrop-filter:initial;
    }
}
@media only screen and (max-width: 600px){
    .popup__layout{
        width: 100%;
    }
}

@media only screen and (max-width: 550px){
    .popup__layout{
        height: calc(100% - 70px);
    }
}