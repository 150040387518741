.popup__layout{
	position: absolute;
	z-index: 3;

	background: white;

	left: 0;
	right: 0;
	margin: 0 auto;
	top: 70px;
	bottom: 100px;

	color: #333;
	cursor: initial;
	overflow: overlay;
	overscroll-behavior: contain;
	
    width: 100%;
	max-width: 700px;
	border-radius: 30px;

	padding: 25px;
	box-sizing: border-box;
}
.header_wrapper{
	padding-bottom: 30px;
}
.header_container{
	display: flex;
	justify-content: space-between;
}
.header_title{
	color: #0060B7;
	font-size: 24px;
	font-weight: 700;
}
/*  */
.main_details{
	margin: 0 0 25px;
}
.field{
    font-size: 16px;
    font-weight: 400;
	color: #0060B7;

}
.field span{
    color: #000;
}
.content_block{
	margin-bottom: 30px;
}
.content_block h3{
	font-size: 16px;
	font-weight: 600;
	margin-bottom: 5px;
}
.content_block ul li{
	list-style-type: '- ';
	margin-bottom: 5px;
	font-size: 16px;
	font-weight: 400;
	margin-left: 20px;
	padding-left: 5px;
}

.btn_container{
    text-align: center;
}
.btn_close{
    border-radius: 16px;
    background: #fff;
	border: 2px solid #0060B7;
    color: #0060B7;
    padding: 10px 50px;
    font-size: 16px;
    margin: 0 0 10px;
	margin-right: 10px;
}
.btn_apply{
    border-radius: 16px;
    background: #0060B7;
    color: #fff;
    padding: 10px 50px;
    font-size: 16px;
    margin: 0;
}

@media only screen and (max-width: 550px){
	.popup__layout{
		border-radius: 30px 30px 0 0;
		height: calc(100% - 70px);
		bottom: 0;
	}
	.btn_container{
		margin-bottom: 40px;
	}
}