.visitor__title_container{
    width: calc(100% - 60px);
    margin: 0 auto;
    padding: 0 50px;
    margin-bottom: 30px;
    
    box-sizing: border-box;
}
.visitor__title_wrapper{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 5px;
}
.visitor__title__h2{
    font-size: 32px;
    font-weight: 700;
    color: #0060B7;
}
.visitor__title_btn_see_more{
    display: flex;
    align-items: center;
    border-radius: 6px;
    border: 1px solid #1F85C5;
    padding: 10px 20px;
    margin-left: 20px;
    color: #1F85C5;
    font-size: 16px;
    font-weight: 700;
    white-space: nowrap;
}
.visitor__title_btn_see_more span{
    margin-right: 10px;
}
.visitor__subtitle{
    color: #1F85C5;
    font-size: 16px;
    margin-bottom: 10px;
}
@media only screen and (max-width: 768px){
    .visitor__title_container{
        padding: 0 20px;
    }
    .visitor__title__h2{
        font-size: 28px;
    }
}
@media only screen and (max-width: 650px){
    .visitor__title_container{
        width: calc(100% - 60px);
        padding: 0;
    }
    .visitor__title__h2{
        font-size: 24px;
    }
}
@media only screen and (max-width: 450px){
    .visitor__title__h2{
        font-size: 20px;
    }
    .visitor__title_btn_see_more{
        padding: 5px 10px;
        margin-left: 10px;
        font-size: 14px;
    }
}