.wrapper{
    display: flex;
    width: calc(100% - 60px);
    flex-wrap: wrap;
}
.card__wrapper{
    margin-bottom: 30px;
    width: 31%;
}

.title{
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 15px;
    height: 50px;
}
.service_img{
    width: 100%;
}
.btn_block{
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
}
.btn_blue{
    border-radius: 10px;
    background: #0060B7;
    color: #fff;
    padding: 10px;
    width: 45%;
    font-size: 16px;
}
.btn_blue:not(:last-child){
    margin-right: 10px;
}

@media only screen and (max-width: 1200px){
    .card__wrapper{
        width: 45%;
    }
}
@media only screen and (max-width: 840px){
    .card__wrapper{
        width: 60%;
    }
}
@media only screen and (max-width: 600px){
    .card__wrapper{
        width: 80%;
    }
}
@media only screen and (max-width: 500px){
    .wrapper{
        width: calc(100% - 30px);
    }
    .card__wrapper{
        width: 100%;
    }
    .btn_block{
        margin-top: 0;
    }
    .title{
        font-size: 18px;
        margin-bottom: 0;
    }
    .btn_blue{
        width: 50%;
        font-size: 14px;
    }
}