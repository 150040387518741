/* Checkbox */
.agreement_block{
    display: flex;
    margin: 10px 0 15px 5px;
}
.agreement_checkbox{
    margin-right: 10px;
    flex-shrink: 0;
    width: 20px;
    height: 20px;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    outline: none;
    border: 2px solid #0060B7;
    border-radius: 0;
    padding: 0;
    cursor: pointer;
    position: relative;
}
.agreement_checkbox::before {
    content: " ";
    position: absolute;
    top: 50%;
    right: 50%;
    bottom: 50%;
    left: 50%;
    transition: all 0.1s;
    background: #0060B7;
}
.agreement_checkbox:checked::before{
    top: 2px;
    right: 2px;
    bottom: 2px;
    left: 2px;
}
.agreement_label{
    width: calc(100% - 20px - 10px);
    cursor: pointer;
    font-size: 14px;
}

/*  */
.item_btn_blue{
    width: 100%;
    border-radius: 20px;
    background-color: #0060B7;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    color: #fff;
    font-size: 16px;
    font-weight: 600;
    padding: 10px 30px;
    outline: none;
}
.item_btn_blue:hover{
    background-color: #004D92;
}
.item_btn_blue:disabled{
    background-color: #004e925c;
}