.footer{
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;

    border-radius: 30px;
    background: linear-gradient(180deg, #FFF 0%, rgba(255, 255, 255, 0.34) 100%);
    box-shadow: 0px 2px 19.8px 0px rgba(26, 31, 36, 0.10);
    width: calc( 100% - 60px );
    max-width: 1380px;
    box-sizing: border-box;

    padding: 15px;
    color: #6AA7CD;
    font-size: 16px;
    text-align: center;
}

.allRights{
    font-weight: 300;
}