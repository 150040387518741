.certificates{
    width: 100%;
    margin-top: 150px;
}

.wrapper{
    display: flex;
    justify-content: space-between;
    width: calc( 100% - 60px );
    margin: 0 auto;
    height: 400px;
}




:global(.mySwiper.swiper){
    overflow: initial;
    overflow-x: hidden;
  }
:global(.mySwiper.swiper .swiper-wrapper){
    height: calc(100% - 40px);
  }
:global(.mySwiper.swiper .swiper-pagination){
    position: relative;
    height: 40px;
    bottom: initial;
    display: flex;
    justify-content: center;
    align-items: center;
  } 
  
:global(.mySwiper.swiper .swiper-slide){
    text-align: center;
    font-size: 18px;
  
    /* Center slide text vertically */
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
:global(.mySwiper.swiper .swiper-slide img){
    height: 80%;
    transition: all 0.3s linear;
    opacity: 0.5;
    box-sizing: border-box;
  }

@media only screen and (max-width: 768px){
  .certificates{
    margin-top: 80px;
  }
}

@media only screen and (max-width: 550px){
    :global(.mySwiper.swiper .swiper-slide img){
      height: 100%;
    }
}
  
  
:global(.mySwiper .swiper-slide.swiper-slide-active img){
    height: 100%;
    transition: all 0.3s linear;
    opacity: 1;
}
  
:global(.mySwiper .swiper-slide img){
    display: block;
    /* width: 100%; */
    /* height: 100%; */
    object-fit: contain;
  
    border-radius: 20px;
    border: 1px solid #8CBEE4;
    background: linear-gradient(0deg, rgba(0, 96, 183, 0.40) 0.19%, rgba(0, 96, 183, 0.04) 100%);
    padding: 16px 12px;
}