.popup__layout{
	position: absolute;
	z-index: 3;

	background: white;

	left: 0; 
    right: 0; 
    margin: 0 auto;
    top: 120px;
	overflow: overlay;
	overscroll-behavior: contain;

	color: #333;
	cursor: initial;

    width: 100%;
	max-width: 600px;
	border-radius: 30px 30px 0 0;

	padding: 25px;
	box-sizing: border-box;
}
.header_date{
    color: #1F85C5;
    font-size: 14px;
    font-weight: 500;
}
.title_container{
    display: flex;
    justify-content: space-between;
}
.header_title{
    color: #0060B7;
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 20px;
}
.popup_layout__close{
    color: #0060B7;
    font-size: 16px;
}
.img{
    width: 100%;
    max-height: 300px;
    margin: 0 20px 20px 0;
}
.content{
    color: #000;
    font-size: 16px;
    font-weight: 400;
    line-height: 150%;
}

.btn_container{
    text-align: center;
}
.btn_close{
    border-radius: 16px;
    background: #0060B7;
    color: #fff;
    padding: 10px 50px;
    font-size: 16px;
    margin: 20px 0 10px;
}