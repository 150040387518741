.card_wrapper{
    width: 100%;
    margin-bottom: 20px;
}
.section_block{
    width: 100%;    
}
.title_block{
    color: #000;
    font-size: 20px;
    font-weight: 600;
    padding: 8px 0 16px 10px;
}
.pl30{
    padding-left: 30px;
}
.btn_add{
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    background: #0060B7;
    color: #FFF;
    font-family: Montserrat;
    font-size: 16px;
    font-weight: 600;
    padding: 10px 16px;
}
.btn_add svg{
    width: 15px;
    height: 15px;
    margin-right: 10px;
}

/* datePickerWrapper */
.date{
    margin-right: 5px;
    font-size: 18px;
}
.card__content .datePickerWrapper{
    padding: 5px 0 5px 25px;
    width: 100%;
    max-width: 150px;
}
/* end of datepicker  */
.radioGroupItem[data-state='checked'] ~ label .date{
    font-weight: 600;
}
.deliveryPayment{
    color: #959595;
    font-size: 14px;
    margin-bottom: 16px;
}

/* selector */
.RadioGroupRoot{
    display: flex;
    justify-content: space-between;
}
.card__content{
    width: 32%;
    box-sizing: border-box;
    padding-bottom: 0;
}
/* Radio Buttons */
.itemsContainer,
.radioGroupSelectorContainer{
    box-shadow: 3px -1px 19.5px 0px rgba(0, 96, 183, 0.25);
    border-radius: 16px;
    
}
.itemsContainer{
    padding: 16px 10px 10px 5px;
    margin-bottom: 5px;
}
.radioGroupSelectorContainer{
    margin: 15px 0;
    padding: 16px;
}
.radioGroupBlock{
    display: flex;
}
.radioGroupItem{
    background-color: white;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 10px;
    padding: 0;
    border: 1px solid #0060B7;
    margin-right: 10px;
}
.radioGroupItem[disabled]{
    border: 1px solid #c8c8c8;
}
.radioGroupItem[disabled] ~ .label{
    color: #c8c8c8;
}
.radioGroupIndicator{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    position: relative;
}
.radioGroupIndicator:after{
    content: "";
    display: block;
    width: 80%;
    height: 80%;
    border-radius: 50%;
    background-color: #0060B7;
}
.label{
    display: flex;
    cursor: pointer;
    flex-direction: column;
    align-items: center;
}
.customDateBtn{
    display: flex;
    align-items: center;
    border: 1px solid #eee;
    border-radius: 8px;
    padding: 5px 20px;
    margin: 5px 0 5px 30px;
    font-size: 14px;
}
.customDateBtn svg{
    margin-right: 5px;
}
/* end of radio buttons */

/* LOADER */
.loading_block{
    display: flex;
    align-items: center;
    padding: 5px 20px;
}
.loading_text{
    margin: 0 5px;
}
.loading_block_error{
    display: flex;
    align-items: center;
    padding: 0 20px;
}
.loading_error{
    height: 30px;
}
/* END OF LOADER */
@media only screen and (max-width: 1230px){
    .RadioGroupRoot{
        flex-direction: column;
    }
    .card__content{
        margin-bottom: 15px;
        width: 100%;
        padding: 20px 10px 10px 20px;
    }
}
@media only screen and (max-width: 850px){
    .deliveryPayment{
        margin-bottom: 10px;
    }
    .card__content{
        padding: 10px 10px 0 20px;
    }
}