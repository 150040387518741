.cart_title_btn{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 0 15px 15px;
}
.btn_reset{
    border: 1px solid #E6E6E6;
    border-radius: 20px;
    padding: 8px 20px;
    color: #1F85C5;
}




.cart__make_order_section{
    display: flex;
    flex-direction: column;
}
.item_btn_blue{
    width: 100%;
    border-radius: 20px;
    background-color: #0060B7;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    color: #fff;
    font-size: 16px;
    font-weight: 600;
    padding: 10px 30px;
    outline: none;
}
.item_btn_blue:hover{
    background-color: #004D92;
}
.item_btn_blue:disabled{
    background-color: #004e925c;
}
.item_btn_green{
    width: 100%;
    border-radius: 20px;
    background-color: #25B700;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    color: #fff;
    font-size: 16px;
    font-weight: 600;
    padding: 10px 30px;
    outline: none;
    border: 2px solid #aed2f2;
}
.item_btn_green:hover{
    background-color: #166d00;
}
.item_btn_green:disabled{
    background-color: #a1d792;
}









/* Order Details */


/* MOBILE */
:global(.cartShown) .cart__btn_quantity{
    padding: 5px 25px;
}
/* END OF MOBILE */

@media only screen and (max-width: 550px){
    .sublevel_svg{
        width: 40px;
        height: 40px;
        margin: 0 0 0 15px;
    }
}