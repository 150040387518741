.wrapper{
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background: linear-gradient(90deg, #f6f9ff 0%, #e9f6fa 36.11%, #eefafe 61.11%, #dde7fb 100%);
}
.loader_5 {
  position: relative;
  height: 120px;
  width: 120px;
  animation: loader-5-1 2s cubic-bezier(0.770, 0.000, 0.175, 1.000) infinite;
}
@keyframes loader-5-1 {
  0%   { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.loader_5::before, .loader_5::after, .loader_5 span::before, .loader_5 span::after {
  content: "";
  position: absolute;
  width: 64px;
  height: 64px;
  
  border-radius: 50%;
  margin: auto;
}
.loader_5::before{
  background: #EE3131;
}
.loader_5::after{
  background: #FFD600;
}
.loader_5 span::before{
  background: #3DB02B;
}
.loader_5 span::after{
  background: #256CF7;
}
.loader_5::before {
  top: 0;
  left: 0;
  animation: loader-5-2 2s cubic-bezier(0.770, 0.000, 0.175, 1.000) infinite;
}
@keyframes loader-5-2 {
  0%   { transform: translate(0, 0) scale(1); }
  50%  { transform: translate(0, 0) scale(0.5); }
  100% { transform: translate(0, 0) scale(1); }
}
.loader_5::after {
  top: 0;
  right: 0;
  animation: loader-5-3 2s cubic-bezier(0.770, 0.000, 0.175, 1.000) infinite;
}
@keyframes loader-5-3 {
  0%   { transform: translate(0, 0) scale(1); }
  50%  { transform: translate(0, 0) scale(0.5); }
  100% { transform: translate(0, 0) scale(1); }
}
.loader_5 span::before {
  bottom: 0;
  left: 0;
  animation: loader-5-4 2s cubic-bezier(0.770, 0.000, 0.175, 1.000) infinite;
}
@keyframes loader-5-4 {
  0%   { transform: translate(0, 0) scale(1); }
  50%  { transform: translate(0, 0) scale(0.5); }
  100% { transform: translate(0, 0) scale(1); }
}
.loader_5 span::after {
  bottom: 0;
  right: 0;
  animation: loader-5-5 2s cubic-bezier(0.770, 0.000, 0.175, 1.000) infinite;
}
@keyframes loader-5-5 {
  0%   { transform: translate(0, 0) scale(1); }
  50%  { transform: translate(0, 0) scale(0.5); }
  100% { transform: translate(0, 0) scale(1); }
}
