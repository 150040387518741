.wrapper{
    display: flex;
    justify-content: center;
    width: calc(100% - 60px);
}
.card__wrapper{
    width: 90%;
    margin: 30px 0;
    display: flex;
    flex-direction: row;
    /* align-items: center; */
    justify-content: space-between;
}

.card__content{
    padding: 30px 20px;
    width: 54%;
    box-sizing: border-box;
}
.content{
    line-height: 1.5;
}
.cont_img{
    box-sizing: border-box;
    width: 45%;
}
.left .cont_img{
    height: 310px;
    order: 1;
}

.right .cont_img{
    height: 400px;
    order: 0;
}
.right .card__content{
    order: 1;
}

@media only screen and (max-width: 1280px){
    .card__wrapper{
        width: 100%;
    }
}
@media only screen and (max-width: 1024px){
    .card__wrapper{
        flex-direction: column;
        width: 90%;
    }
    .card__content{
        width: 100%;
    }
    .cont_img{
        width: 100%;
        margin-top: 15px;
    }
    .left .cont_img,
    .right .cont_img,
    .right .card__content{
        order:initial
    }
}
@media only screen and (max-width: 768px){
    .card__wrapper{
        width: 100%;
    }
}
@media only screen and (max-width: 550px){
    .wrapper{
        width: calc(100% - 30px);
    }
}