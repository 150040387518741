.about_company{
    margin-top: 80px;
}
.aboutCard{
    display: flex;
    max-height: 420px;
}
.card__wrapper{
    display: flex;
    height: 290px;
    width: 80%;
    align-items: center;
    box-sizing: border-box;
    flex-direction: row;
}
.about_img{
    border-radius: 20px;
    margin-right: 10px;
}
.img_block{
    display: flex;
}

@media only screen and (max-width: 1024px){
    .card__wrapper{
        width: calc(100% - 30px);
    }
}
@media only screen and (max-width: 768px){
    .about_company{
        margin-top: 40px;
    }
    .card__wrapper{
        flex-direction: column;
        height: auto;
    }
    .img_block{
        margin-bottom: 10px;
    }
}
@media only screen and (max-width: 550px){
    .about_company{
        margin-top: 0;
    }
}
@media only screen and (max-width: 450px){
    .img_block{
        display: flex;
        justify-content: space-between;
    }
    .about_img{
        margin: 0;
        width: 48%;
    }
}