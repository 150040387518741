/* OUR BENEFITS */  
  .partners{
    width: 100%;
    margin-top: 150px;
}

.partners_wrapper{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    width: calc( 100% - 60px );
    margin: 0 auto;
}


/* animation */  
.slider {
    /* height: 70px; */
    position: relative;
    width: 100%;
  
    display: grid;
    place-items: center;
    overflow: hidden;

    mask-image: linear-gradient(
        var(--mask-direction, to right),
        hsl(0 0% 0% / 0),
        hsl(0 0% 0% / 1) 20%,
        hsl(0 0% 0% / 1) 80%,
        hsl(0 0% 0% / 0)
    );
  }

  
  /*  IMPORTANT CODE BELOW */
  
  
  .slide__track {
    width: calc(180px * 15);
    display: flex;
    animation: scroll2 45s linear infinite;
    justify-content: space-between;
  }
  .slide__track.reverse{
    animation-direction: reverse;
  }
  .slide {
    width: 160px;
    /* height: 60px; */
  
    display: grid;
    place-items: center;
    transition:0.5s;
    cursor:pointer;

    border-radius: 36px;
    border: 1.8px solid #8CBEE4;
    background: linear-gradient(0deg, rgba(0, 96, 183, 0.40) 0.2%, rgba(0, 96, 183, 0.04) 99.8%);
    padding: 7px;
    margin: 12px;
  }
  .slide:hover{
    transform:scale(0.8)
  }

  .slide img{
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
  
  @keyframes scroll {
    0% {
      transform: translateX(0px);
    }
    100% {
      transform: translateX(calc(-150px * 10));
    }
  }
  
  @keyframes scroll2 {
    0% {
      transform: translateX(0px);
    }
    100% {
      transform: translateX(calc(-150px * 5));
    }
  }
  

  @media only screen and (max-width: 1024px){
    .partners{
      margin-top: 80px;
    }
  }



  @media screen and (max-width: 768px) {
      .slide__track {
        width: calc(130px * 15);
      }
        
      .slide {
        width: 110px;
      }
    
      @keyframes scroll {
        0% {
          transform: translateX(0px);
        }
        100% {
          transform: translateX(calc(-80px * 10));
        }
      }
    
      @keyframes scroll2 {
        0% {
          transform: translateX(0px);
        }
        100% {
          transform: translateX(calc(-80px * 5));
        }
      }
  }

@media screen and (max-width: 500px) {
  .slide__track {
    width: calc(100px * 15);
  }
  .slide{
    width: 110px;
    margin: 6px;
  }
}