:global(.visitor__title_container){
    width: 30%;
    margin: 30px auto;
    margin-bottom: 40px;
    text-align: center;
}
:global(.visitor__title_wrapper){
    margin-bottom: 10px;
}
:global(.visitor__title__h2){
    font-size: 32px;
    font-weight: 700;
    color: #0060B7;
    margin-bottom: 10px;
}
:global(.visitor__subtitle){
    color: #1F85C5;
    font-size: 16px;
}
  
:global(.visitor__title__btn_see_more){
    display: flex;
    align-items: center;
    border-radius: 6px;
    border: 1px solid #1F85C5;
    padding: 4px 10px;
    margin-left: 20px;
    color: #0060B7;
}
:global(.visitor__title__btn_see_more span){
    margin-right: 10px;
}

@media only screen and (max-width: 768px){
    :global(.visitor__title_container){
        width: 80%;
    }
}

