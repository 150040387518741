.popup__layout{
	position: absolute;
	z-index: 3;

	background: white;

	left: 0; 
    right: 0; 
    margin: 0 auto;
    top: 120px;

	overflow: overlay;
	overscroll-behavior: contain;

	color: #333;
	cursor: initial;

	width: 95%;
    max-width: 500px;
	border-radius: 30px;

	padding: 25px;
	box-sizing: border-box;
}
.header_container{
    display: flex;
    justify-content: space-between;
    margin-bottom: 12px;
}
.header_title{
    font-size: 16px;
    color: #0060B7;
}

.title{
    color: #0060B7;
    font-size: 18px;
    text-align: center;
    font-weight: 700;
}
.message{
    color: #0060B7;
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 16px;
}
.form{
    margin-top: 16px;
}
/* Radio Buttons */
.radioGroupBlock{
    display: flex;
    box-shadow: 3px -1px 19.5px 0px rgba(0, 96, 183, 0.25);
    border-radius: 16px;
    padding: 16px;
    margin-bottom: 10px;
}
.radioGroupItem{
    background-color: white;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 10px;
    padding: 0;
    border: 1px solid #0060B7;
    margin-right: 10px;
}
.radioGroupIndicator{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    position: relative;
}
.radioGroupIndicator:after{
    content: "";
    display: block;
    width: 80%;
    height: 80%;
    border-radius: 50%;
    background-color: #0060B7;
}
.label{
    cursor: pointer;
    width: calc(100% - 40px);
}
/*  */
.content_text{
    color: #000;
    font-size: 16px;
    font-weight: 400;
}
.comment_text{
    margin-top: 10px;
}
.btn_change_container{
    display: flex;
    width: 100%;
    justify-content: flex-end;
}
.btn_change_info{
    color: #0060B7;
    font-family: Montserrat;
    font-size: 16px;
    font-weight: 400;
    text-decoration-line: underline;
}



.btn_container{
    display: flex;
    flex-direction: column;
    align-items:center;
}
.btn_block{
    display: flex;
    justify-content: center;
}
.btn_add_address{
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    color: #0060B7;
    margin: 15px 0 25px;
    display: flex;
    align-items: center;
}
.icon_plus{
    width: 15px;
    height: 15px;
    margin-right: 5px;
}
.icon_plus path{
    fill: #0060B7;   
}

.item_btn_blue_border,
.item_btn_blue_filled{
    font-size: 16px;
    font-weight: 600;
    padding: 8px 30px;
    border-radius: 16px;
}
.item_btn_blue_border{
    border: 2px solid #0060B7;
    color: #0060B7;
    margin-right: 15px;
}
.item_btn_blue_filled{
    background-color: #0060B7;
    color: #fff;
}
/*  */
.noSavedData{
    font-size: 16px;
    font-weight: 400;
    padding: 10px 0;
}
.deleteBlock{
    color: #B00000;
    font-size: 16px;
    font-weight: 600;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #E6E6E6;
    border-top: 1px solid #E6E6E6;
    padding: 16px 0;
    cursor: pointer;
}
.deleteBlock svg{
    margin-right: 10px;
}
/* add */
.address_info_container{
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
}
.addStreet{
    margin-bottom: 16px;
}
.address_info_block{
    display: flex;
    flex-direction: column;
    width: 48%;
}
label.error{
    color: #f00;
}
input.error,
textarea.error{
    outline: none;
    border: 1px solid #f00;
}
.addCityLabel{
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 5px;
    color: #0060B7;
    display: flex;
}

.addCitySelect{
    width: 100%;
    padding: 10px;
    background: #fff;
    height: 45px;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    border-radius: 16px;
    border: 1px solid #000;
    font-size: 16px;
    margin-bottom: 16px;
}
.addCommentText{
    border: 1px solid #AFAFAF;
    width: 100%;
    border-radius: 4px;
    padding: 10px;
    background: #fff;
    box-sizing: border-box;
    border-radius: 16px;
    margin-bottom: 16px;
    font-size: 16px;
    height: 75px;
}