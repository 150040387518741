.v_layout{
    width: 100%;
    max-width: 1440px;
    box-sizing: border-box;
    margin: 0 auto;
    display: flex;
    align-items: center;
    flex-direction: column;

    padding-top: 100px;
    border-width: 1px;
    border-top: none;
    border-bottom: none;
    border-style: solid;
    border-color: rgba(255, 255, 255, 0.40);
    box-shadow: 3px -1px 19.5px 0px rgba(0, 96, 183, 0.25);
    
}

.allRightsDevelopedBy{
    padding: 15px;
    color: #6AA7CD;
    font-size: 16px;
    text-align: center;
}

.allRights{
    font-weight: 300;
}
.developedBy a{
    color: #006fb5;
    font-weight: 600;
}

@media only screen and (max-width: 550px){
    :global(#root){
        border-radius: 30px;
    }
    .v_layout{
        border-radius: 30px;
        box-shadow: none;
    }
}