.footer{
    margin-top: 150px;
    color: #0060B7;
}

.footer address{
    display: flex;
    flex-direction: column;
    font-style: normal;
}

.footer .logo{
    width: 160px;
}


.footer__container{
    display: flex;
    flex-direction: column;
    height: 310px;
    flex-wrap: wrap;
}

.footer h4{
    margin: 25px 0 15px 0;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
}

.footer a{
    color: #0060B7;
    display: flex;
    align-items: center;
    margin: 5px 0;
    font-weight: 300;
    font-size: 16px;
}

.card__content{
    padding: 30px;
}

.footer_column{
    width: 33.33%;
}


/* LEFT */
.logo__social{
    width: 30px;
    height: 30px;
}
.logo__social:not(:last-child){
    margin-right: 30px;
}

/* MIDDLE */
.logo__btn{
    width: 24px;
    height: 24px;
    margin-right: 10px;
}
.address__link_container{
    display: flex;
}
.address__link_container a{
    border-radius: 50px;
    border: 1px solid #007FC9;
    padding: 5px 20px 5px 10px;
    font-size: 16px;
}
.address__link_container a:not(:last-child){
    margin-right: 15px;
}
.logo__maps{
    border-radius: 50%;
}
.middle__workingHours{
    font-weight: 300;
    margin-bottom: 15px;
}
.day_of_the_week_label{
    width: 55px;
    display: inline-block;
}
.day_of_the_week_text{
    font-weight: 500;
}
.svg_container{
    background-color: #1F85C5;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    margin-right: 10px;
}

.footer_docs_block{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}
a.policy_link{
    color: #AFAFAF;
    font-size: 16px;
    font-weight: 500;
    text-decoration-line: underline;
    margin: 10px;
}

@media only screen and (max-width: 1250px){
    .footer{
        margin-top: 100px;
    }
    .footer__container{
        height: 400px;
    }
    .footer_contacts{
        order: 1;
    }
    .footer_address{
        order: 2;
    }
    .footer_column{
        width: 50%
    }
}

@media only screen and (max-width: 850px){
    .footer{
        margin-top: 60px;
    }
    .footer__container{
        height: 800px;
        align-items: center;
    }
    .footer_contacts{
        order: 1;
    }
    .footer_address{
        order: 2;
    }
    .footer_column{
        width: 100%;
    }
}

@media only screen and (max-width: 550px){
    .footer{
        width: calc( 100% - 20px);
    }
}
 @media only screen and (max-width: 500px){
    .footer__container{
        height: 100%;
    }
    .address__link_container{
        flex-direction: column;
        align-items: start;
    }
}