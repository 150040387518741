.header{
    display: flex;
    padding: 10px 0 10px 10px;
}
.msg_delivery{
    display: flex;
    background-color: #E6F0F8;
    width: 100%;
    align-items: center;
    border-radius: 25px;
    padding: 3px 0 3px 10px;
}
.msg_delivery img{
    margin-right: 10px;
}
.btnDeliverySchedule{
    font-size: 16px;
    border-radius: 10px;
    border: 1px solid #014788;
    color: #014788;
    margin: 0 5px;
    padding: 3px 15px;
}
.popup_layout__close{
    margin-left: auto;
    outline: none;
    border: none;
    background-color: transparent;
    padding: 20px 25px 10px 15px;
    font-size: 16px;
    cursor: pointer;
    margin-top: -10px;
}
.btn_go_back{
    display: flex;
    align-items: center;
    color: #4a86c8;
    font-size: 14px;
}
.btn_go_back img{
    width: 16px;
    height: 16px;
    margin-top: 3px;
    margin-right: 5px;
}