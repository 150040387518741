.wrapper{
    display: flex;
    width: calc(100% - 120px);
    flex-wrap: wrap;
}
.main_container{
    width: 33.33%;
}
.card__wrapper{
    margin-bottom: 20px;
    width: 90%;
}

.title{
    font-size: 20px;
    font-weight: 600;
}
.category{
    color: #000;
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 20px;
}
.field{
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 5px;
}
.field span{
    color: #000;
}
.btn_block{
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
}
.btn_blue{
    border-radius: 10px;
    background: #0060B7;
    color: #fff;
    padding: 10px;
    width: 45%;
    font-size: 16px;
}
.btn_blue:not(:last-child){
    margin-right: 10px;
}
.no_vacancies{
    color: #0060B7;
    text-align: center;
    font-size: 16px;
    font-weight: 600;
}

/* no vacancies */
.no_vacancies_wrapper{
    width: calc(100% - 120px);
}
.no_vacancies_card{
    width: 100%;
    max-width: 400px;
}
/* end of no vacancies */

@media only screen and (max-width: 1280px){
    .main_container{
        width: 50%;
    }
    .card__wrapper{
        width: 70%;
    }
}

@media only screen and (max-width: 1150px){
    .card__wrapper{
        width: 80%;
    }
}
@media only screen and (max-width: 1024px){
    .card__wrapper{
        width: 90%;
    }
}

@media only screen and (max-width: 900px){
    .main_container{
        width: 100%;
    }
    .card__wrapper{
        width: 60%;
    }
}
@media only screen and (max-width: 700px){
    .card__wrapper{
        width: 80%;
    }
}
@media only screen and (max-width: 570px){
    .wrapper{
        width: calc(100% - 30px);
    }
    .card__wrapper{
        width: 90%;
    }
}
@media only screen and (max-width: 510px){
    .card__wrapper{
        width: 100%;
    }
}