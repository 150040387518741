/* Order details Cart Total */
.orderDetails_cartTotal_container{
    padding: 5px 0 20px 10px;
    width: 100%;
    box-sizing: border-box;
}
.orderDetails_cartTotal_title{
    color: #000;
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 5px;
}
.orderDetails_cartTotal_block{
    display: flex;
    justify-content: space-between;
    margin-bottom: 5px;
}
.orderDetails_cartTotal_text{
    font-size: 16px;
    color: #000;
    font-weight: 600;
}