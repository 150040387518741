.promotion{
    width: 100%;
    margin-top: 150px;
}

.wrapper{
    display: flex;
    justify-content: space-between;
    width: calc( 100% - 60px );
    margin: 0 auto;
}

.card__wrapper{
    width: 31%;
}