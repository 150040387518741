.section_container{
    width: calc(100% - 360px);
}

.title_page{
    color: #0060B7;
    font-size: 32px;
    font-weight: 700;
    margin-bottom: 16px;
    margin-left: 16px;
}



@media only screen and (max-width: 850px){
    .section_container{
        width: 100%;
        display: flex;
        justify-content: center;
    }
    .inner_container{
        width: 95%;
    }
}