.accordionRoot{
    background-color: #fff;
    border-radius: 16px;
    padding: 10px;
}
.accordionHeader{
    position: relative;
}
.accordionHeader::before{
    content: "";
    position: absolute;
    bottom: 0;
    border-bottom: 1px solid #b5d3f5;
    width: calc(100% - 15px - 15px);
    left: 15px;
}
.accordionItem[data-state='open'] .accordionHeader::before{
    border-bottom: none;
}
/* Trigger */
.accordionTrigger{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    color: #000;
    font-size: 16px;
    font-style: normal;
    padding: 12px 16px;
    font-weight: 500;
    border-radius: 16px;
    cursor: pointer;
}
.accordionTrigger[data-state='open']{
    background: #0060B7;
    color: #fff;
}
.accordionTriggerName{
    text-align: left;
    width: 100%;
}
.accordionChevron {
    transition: transform 300ms;
    margin-left: 15px;  
    width: 16px;
    height: 16px;  
  }
.accordionTrigger[data-state='open'] > .accordionChevron {
    transform: rotate(90deg);
}
.accordionChevron path{
    stroke: #000;
}
.accordionTrigger[data-state='open'] > .accordionChevron path{
    stroke: #fff;
}
/* Content */
.accordionContent{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.accordionContentText{
    width: 80%;
}
.accordionContentDoc{
    display: flex;
    justify-content: space-between;
}
.accordionContentLeft,
.accordionContentRight{
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.accordionContentLeft img{
    width: 80%;
    height: 80%;
    object-fit: contain;
    box-sizing: border-box;
}
.accordionContentRight{
    flex-direction: column;
}
.language{
    font-weight: 500;
    font-size: 18px;
    margin: 16px 0 11px;
    margin-top: 0;
}
.btn_blue{
    display: flex;
    border-radius: 15px;
    background: #0060B7;
    padding: 4px 16px;
    align-items: center;
    color: #fff;
    margin: 5px 0;
    font-size: 14px;
}
.btn_blue img{
    margin-right: 10px;
    width: 20px;
    height: 20px;
}
/* Animations */
@keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  @keyframes fadeOut {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }
  .DialogOverlay[data-state='open'],
  .DialogContent[data-state='open'] {
    animation: fadeIn 300ms ease-out;
  }
  .DialogOverlay[data-state='closed'],
  .DialogContent[data-state='closed'] {
    animation: fadeOut 300ms ease-in;
  }