.popup__layout{
	position: absolute;
	bottom: 0;
	z-index: 3;

	background: linear-gradient(90deg, #f6f9ff 0%, #e9f6fa 36.11%, #eefafe 61.11%, #dde7fb 100%);

    left: 0;
    right: 0;
	margin: 0 auto;
	overflow: overlay;
	overscroll-behavior: contain;

	color: #333;
	cursor: initial;

	width: 100%;
	max-width: 1440px;
	height: calc(100% - 110px);
	border-radius: 30px 30px 0 0;

	padding: 25px;
	box-sizing: border-box;
}
.catalog__container{
    display: flex;
    justify-content: space-between;
    color: #0060B7;
    padding-bottom: 20px;
}
.catalog__left{
    width: 230px;
    display: flex;
    flex-direction: column;
    align-items: center;
    align-self: flex-start;
    box-sizing: border-box;
}
.catalog__middle{
    width: calc(100% - 230px - 10px - 10px);
    margin: 0 10px;
}
.catalog__right{
    width: 340px;
    align-items: center;
    align-self: flex-start;
    background-color: #fff;
    box-shadow: 0px 4px 19px 0px rgba(0, 0, 0, 0.20);
}
.catalog__left,
.catalog__middle,
.catalog__right{
    border-radius: 20px;
    /* box-shadow: 0px 4px 19px 0px rgba(0, 0, 0, 0.10); */
    padding: 20px 10px;
    box-sizing: border-box;
}

.catalog__container h2{
    font-size: 32px;
    font-weight: 600;
}
.catalog__container h3{
    font-size: 26px;
    font-weight: 600;
}
.catalog__container h4{
    font-size: 22px;
    font-weight: 600;
}

.catalog__container h5{
    font-size: 18px;
    font-weight: 600;
}

.left_middle_col_block{
    display: flex;
    width: calc(100% - 290px);
}




/* Mobile orderDetails Start button "Оформить х тг" */
.open_orderDetails_start{
    position: fixed;
    bottom: 40px;
    left: 0;
    right: 0;
    margin: 0 auto;
    width: 100%;
    max-width: 300px;
}
.item_btn_blue{
    width: 100%;
    border-radius: 20px;
    background-color: #0060B7;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    color: #fff;
    font-size: 16px;
    font-weight: 600;
    padding: 10px 0;
    outline: none;
    border: 2px solid #aed2f2;
}
.item_btn_blue:hover{
    background-color: #004D92;
}
.item_btn_blue:disabled{
    background-color: #004e925c;
}
.item_btn_green{
    width: 100%;
    border-radius: 20px;
    background-color: #25B700;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    color: #fff;
    font-size: 16px;
    font-weight: 600;
    padding: 10px 30px;
    outline: none;
    border: 2px solid #aed2f2;
}
.item_btn_green:hover{
    background-color: #166d00;
}
.item_btn_green:disabled{
    background-color: #a1d792;
}
/* CART OPEN */
:global(.cartShown).catalog__container{
    justify-content: center;
}
:global(.cartShown) .catalog__right{
    width: 95%;
}

/* end of mobile */

/* LOADER */
.loading_block{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
}
.loading_title{
    font-size: 32px;
    font-weight: 700;
    color: #0060B7; 
    margin-bottom: 25px;  
}
.loading_text{
    color: #1F85C5;
    font-weight: 500;
    margin-top: 25px;
}
.loading_error{
    width: 64px;
    height: 64px;
}
.loading_btnRefresh{
    margin-top: 25px;
}
/* END OF LOADER */
@media only screen and (max-width: 1024px){
    .left_middle_col_block{
        flex-direction: column;
    }
    .catalog__left{
        flex-direction: row;
        flex-wrap: wrap;
        padding: 10px 10px 5px 10px;
        margin: 0 10px 10px 0;
    }
    .catalog__middle{
        margin: 0 10px 0 0;
    }
    .catalog__left,
    .catalog__middle{
        width: calc(100% - 10px);
    }
}

@media only screen and (max-width: 850px){
    .popup__layout{
        padding: 0;
    }
    .catalog__left,
    .catalog__middle{
        padding: 15px;
        margin: 0 0 10px 0;
    }
    .catalog__left{
        margin: 0;
    }
    .catalog__right{
        margin-bottom: 110px;
    }
    .catalog__middle{
        padding-bottom: 80px;
    }
    .left_middle_col_block{
        width: 100%;
        align-items: center;
    }
}

@media only screen and (max-width: 550px){
    .popup__layout{
        height: calc(100% - 70px);
    }
}
