.cart_block{
    display: flex;
    flex-direction: column;
    min-height: 90px;
    max-height: 145px;
}
.cart_img_title_code_block{
    display: flex;
    justify-content: space-between;
}
/* LEFT */
.cart__left{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20%;
}
.cart__left img{
    height: 100%;
    width: 100%;
    object-fit: contain;
    max-height: 80px;
}
.cart__right{
    width: 77%;
}
.cart__item_name{
    color: #004787;
    font-size: 14px;
    font-weight: 600;
}
.cart__item_code{
    color: #1F85C5;
    font-size: 12px;
    font-weight: 400;
}
.cart__details{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 10px 0;
    width: 100%;
}
.cart__btn_block{
    color: #004787;
    font-size: 16px;
    font-weight: 600;
}
.cart__btn_quantity{
    border: 1px solid #E6E6E6;
    border-radius: 20px;
    padding: 3px 15px;
    
    font-size: 15px;
    font-weight: 600;
    color: #1F85C5;
}
.cart__btn_quantity:disabled{
    color: #E6E6E6;
}
.cart_quantity_label{
    margin: 0 5px;
}
.cart__item_price{
    font-weight: 600;
    padding: 0 0 0 25px;
}


