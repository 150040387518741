/* OUR BENEFITS */
.catalog{
    width: 100%;
    margin-top: 150px;
}

.wrapper{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    width: calc( 100% - 160px );
    margin: 0 auto;
}

.box{
    width: 31%;
    margin-bottom: 30px; 
}
.card__wrapper{
    width: 100%;
    padding: 20px 10px 10px;
}
.card_title{
    padding: 0 20px;
    color: #0F5FA6;
    font-size: 22px;
    font-weight: 700;
    height: 66px;
}
.card__content{
    height: 170px;
    box-sizing: border-box;
    text-align: center;
    padding: 0;
}
.card_img{
    box-sizing: border-box;
    margin-top: -40px;
    height: 200px;
}
.btn_block{
    display: flex;
    justify-content: center;
    margin: 10px 0 0 0;
}
.card_btn{
    padding: 10px 20px;
    font-size: 16px;
    font-weight: 600;
    border-radius: 10px;
    background: #0060B7;
    color: #fff;
}
.card_btn:first-child{
    margin-right:20px;
}

@media only screen and (max-width: 1024px){
    .catalog{
        margin-top: 80px;
    }
    .box{
        width: 45%;
    }
}
@media only screen and (max-width: 768px){
    .wrapper{
        width: calc(100% - 60px);
    }
    .box{
        width: 48%;
    }
}
@media only screen and (max-width: 650px){
    .wrapper{
        width: calc(100% - 30px);
        justify-content: center;
    }
    .box{
        width: 70%;
    }
}
@media only screen and (max-width: 578px){
    .box{
        width: 90%;
    }
}
@media only screen and (max-width: 500px){
    .box{
        width: 100%;
    }
    /* .card__content{
        height: 125px;
    }
    .card_img{
        margin-top: -30px;
        height: 150px;
    } */
}