:global(.modal:not(.modal--active) .modal__container) {
	overflow: hidden;
}

/* :global(.modal--active) .popup__layout{
	top: 0;
} */
:global(.modal--active) .main_container{
	/* transition: opacity 0.3s cubic-bezier(0.77,0.2,0.05,1.0); */
    opacity: 1;
}
.popup_layout__container{
	width: 100%;
	height: 100%;
}
.popup_layout__inner{
	width: 100%;
	height: 100%;
}
.wrapper{
	/* transition: opacity 0.3s cubic-bezier(0.77,0.2,0.05,1.0); */
	width: 100%;
	height: 100%;
    /* transition: 250ms ease; */
    opacity: 1;
}
.backdrop{
	display: none;
}
.main_container{
	transition: 250ms ease;
	opacity: 0;
}
:global(.modal--active) .backdrop{
    display: flex;
    width: 100%;
	height: 100%;
	z-index: 2;
	background-color: #282828bc;
	top: 0;
	position: fixed;
}


