.wrapper{
    display: flex;
    width: calc(100% - 60px);
    flex-wrap: wrap;
    margin-bottom: 40px;
}
.wrapper :global(.visitor__title_container){
    width: 100%;
}
.card__wrapper,
.card__wrapper_2{
    width: 100%;
}
.content_block{
    display: flex;
    margin-top: 25px;
    flex-wrap: wrap;
    justify-content: space-between;
}
.card{
    width: 32%;
    box-sizing: border-box;
}
.header{
    display: flex;
    align-items: flex-end;
    padding-bottom: 15px;
    margin-top: -35px;
}
.header img{
    border-radius: 9px;
    background: var(--TextDefault, #0060B7);
    padding: 9px;
    margin-right: 10px;
    height: 50px;
    width: 50px;
}

/*  */
.wrapper_2{
    display: flex;
    width: 80%;
    margin-bottom: 20px;
}
.card_2{
    display: flex;
}
.left{
    margin-right: 10px;
}
.right img{

}
.offer_img{
    width: 100%;
    box-sizing: border-box;
}
.we_offer{
    color: #0060B7;
    font-size: 20px;
    font-weight: 500;
}
.list{
    color: #000;
    font-size: 16px;
    font-weight: 400;
}
ul.list li{
    list-style-type: '- ';
    margin-bottom: 5px;
}
.design{
    font-size: 16px;
    font-weight: 600;
}
@media only screen and (max-width: 1280px){
    .card__wrapper,
    .card__wrapper_2{
        width: 70%;
    }
    .content_block{
        justify-content: center;
    }
    .card{
        width: 90%;
        margin-bottom: 40px;
    }
    .header{
        margin-top: -50px;
    }
}
@media only screen and (max-width: 1150px){
    .card__wrapper{
        width: 80%;
    }
    .card{
        width: 90%;
    }
}
@media only screen and (max-width: 1000px){ 
    .card__wrapper_2{
        width: 60%;
    }
    .card_2{
        flex-direction: column;
    }
    .left{
        margin-right: 0;
        margin-bottom: 10px;
    }
}
@media only screen and (max-width: 768px){
    .card__wrapper{
        width: 90%;
    }
    .card{
        width: 95%;
    }
    .card__wrapper_2{
        width: 80%;
    }
}
@media only screen and (max-width: 650px){
    .header{
        margin-top: -5px;
        align-items: center;
    }
}


@media only screen and (max-width: 550px){
    .wrapper,
    .wrapper_2{
        width: calc(100% - 30px);
    }
    .card__wrapper{
        width: 100%;
    }
    .card{
        width: 100%;
    }
    .card__wrapper_2{
        width: 100%;
    }
    :global(.visitor__title_h2){
        font-size: 24px;
    }
    .title{
        font-size: 18px;
    }
}

@media only screen and (max-width: 480px){
    .header{
        margin-top: -35px;
        align-items: flex-end;
    }
}

@media only screen and (max-width: 420px){
    .card{
        margin-bottom: 50px;
    }
    .header{
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-top: -45px;
    }
    .header img{
        margin: 0 auto 10px;
    }
    .title{
        text-align: center;
    }
    .content{
        text-align: center;
    }
}