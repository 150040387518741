.category__block{
    margin-bottom: 20px;
}
.category__title{
    margin-bottom: 10px;
}
.items__wrapper{
    display: flex;
    flex-wrap: wrap;
}
.item__container{
    width: 25%;
    display: flex;
    justify-content: center;
}
.item__block_many{
    width: 95%;
    display: flex;
    flex-direction: column;
    padding: 12px;
    box-sizing: border-box;
    border-radius: 15px;
    cursor: pointer;
}
.item__block_one,
.item__block_many{
    box-shadow: 0px 2px 19.8px 0px rgba(26, 31, 36, 0.2);
    margin-bottom: 15px;
    background-color: #fff;
}

/* BLOCK MANY */
.item__block_many .item__item_code{
    text-align: right;
    font-size: 12px;
    font-weight: 400;
    color: #1F85C5;
}
.item__block_many .item_name{
    font-size: 14px;
    font-weight: 500;
    min-height: 36px;
}


.item__block_many .details_img_block{
    display: flex;
    height: 130px;
    margin-bottom: 12px;
}
.item__block_many .item_order_tags{
    width: 30%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.item__block_many .item_order_img_block{
    width: 70%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.item__block_many .item_order_img_block img{
    max-width: 100px;
    max-height: 130px;
}
.item__block_many .item_price,
.item__block_one .item_price{
    color: #004787;
    font-size: 18px;
    font-weight: 700;
    margin: 0 0 12px;
    margin-top: auto;
    text-align: center;
}
.item__block_many .item_price span,
.item__block_one .item_price span{
    color: #909090;
    font-size: 14px;
    font-weight: 400;
}

.item__block_many .item_btn_green,
.item__block_one .item_btn_green{
    border-radius: 20px;
    background-color: #25B700;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    color: #fff;
    font-size: 16px;
    font-weight: 600;
    padding: 5px 20px;
    outline: none;
    display: flex;
    align-items: center;
    justify-content: center;
}
.item__block_many .iconCheck{
    margin-right: 5px;
}
.item__block_many .item_btn_blue,
.item__block_one .item_btn_blue{
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 20px;
    background-color: #0060B7;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    color: #fff;
    font-size: 16px;
    font-weight: 600;
    padding: 5px 20px;
    outline: none;
}
.item__block_many .item_btn_blue:hover,
.item__block_one .item_btn_blue:hover{
    background-color: #004D92;
}
.item__block_many .order_detail{
    border-radius: 8px;
    background: #A5BCD2;
    font-size: 10px;
    text-align: center;
    padding: 5px 3px;
    color: #fff;
}
.item__block_many  .order_detail:not(:last-child){
    margin-bottom: 15px;
}
.item__block_many  .details_img_block img{
    margin: 0 auto;
}

/* ONE item */
.item__block_one{
    width: 95%;
    display: flex;
    padding: 12px;
    box-sizing: border-box;
    border-radius: 15px;
    cursor: pointer;
    justify-content: space-between;
}
.item__block_one .item_img_container{
    width: 90px;
    height: 150px;
}
.item__block_one .item_order_img_block{
    height: 100%;
    justify-content: center;
    display: flex;
    align-items: center;
}
.item__block_one .item_order_img_block img{
    height: 90%;
    width: 90%;
    object-fit: contain;
}

.item__block_one .item_content_container{
    width: calc(100% - 105px);
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}



.item__block_one .item__item_code{
    color: #1F85C5;
    font-size: 14px;
    font-weight: 400;
}
.item__block_one .item_name{
    color: #004787;
    font-size: 16px;
    font-weight: 600;
}
.item__block_one .price_add_btn_block{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.item__block_one .item_order_tags{
    display: flex;
}
.item__block_one .order_detail{
    display: flex;
    color: #717171;
    align-items: center;
}
.item__block_one .order_detail.items_in_package{
    width: 40%;
    justify-content: center;
}
.item__block_one .order_detail.items_in_package.hide{
    opacity: 0;
}
.item__block_one .order_detail.min_order{
    width: 60%;
    justify-content: flex-start;
}
.item__block_one .order_detail svg{
    margin-right: 10px;
}
.item__block_one .order_detail svg path{
    fill: #717171;
}

.outOfStock{
    filter: grayscale(100%);
    opacity: 0.7;
    cursor: initial;
    pointer-events: none;
}

@media only screen and (max-width: 1350px){
    .item__container{
        width: 33.33%;
    }
    .item__block_many{
        width: 90%;
    }
}
@media only screen and (max-width: 1300px){
    .item__block_many{
        width: 95%;
    }
}
@media only screen and (max-width: 1200px){
    .item__container{
        justify-content: flex-start;
        width: 100%;
    }
    .item__block_one{
        width: 100%;
    }
}

@media only screen and (max-width: 425px){
    .item__block_one .item_btn_blue{
        font-size: 14px;
    }
    .item_order_tags{
        flex-direction: column;
    }
    .item__block_one .order_detail.min_order,
    .item__block_one .order_detail.items_in_package{
        width: 100%;
        justify-content: flex-start;
    }
    .item_order_tags .order_detail.min_order{
        margin-bottom: 3px;
    }
}