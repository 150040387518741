.vacancies{
    width: 100%;
    margin-top: 150px;
}

.wrapper{
    display: flex;
    justify-content: space-between;
    width: calc( 100% - 60px );
    margin: 0 auto;
}

.card__wrapper{
    width: 100%;
    max-width: 800px;
}

.card{
    background-color: #1F85C5;
    color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.card__txt_main{
    font-size: 26px;
    font-weight: 600;
}

a.btn_see_more{
    background-color: #fff;
    padding: 6px 20px;
    margin: 40px 0 30px 0;
    color: #0060B7;
    font-size: 18px;
    font-weight: 500;
}

.card__txt_additional{
    font-size: 17px;
}
@media only screen and (max-width: 1024px){
    .vacancies{
        margin-top: 80px;
    }
}