.main_container{
    width: 100%;    
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.40) 0.19%, rgba(255, 255, 255, 0.00) 99.8%);
    box-shadow: 3px -1px 19.5px 0px rgba(0, 96, 183, 0.25);
    padding: 10px;
    border-radius: 30px;
    margin-bottom: 20px;
    box-sizing: border-box;
}
.address_contacts_delivery_container{
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
}
.card_wrapper{
    width: 49%;
    margin-bottom: 20px;
    margin: 0;

    background: none;
    box-shadow: none;
    padding: 0;
}
.section_block{
    display: flex;
    justify-content: space-between;
}
.section_part{
    width: 100%;
    
}
.title_block{
    color: #000;
    font-size: 20px;
    font-weight: 600;
    padding: 8px 0 16px 10px;
}
.card__content{
    box-sizing: border-box;
    padding: 15px 20px;
}
.content_text{
    color: #000;
    font-size: 16px;
    font-weight: 400;
}
.comment_text{
    margin-top: 10px;
}
.btn_change_container{
    display: flex;
    width: 100%;
    justify-content: flex-end;
}
.btn_change_info{
    color: #0060B7;
    font-family: Montserrat;
    font-size: 16px;
    font-weight: 400;
    text-decoration-line: underline;
}

.btn_add_address{
    background-color: #0060B7;
    border-radius: 10px;
    background: #0060B7;
    padding: 8px 16px;
    color: #fff;
    display: flex;
    align-items: center;
    font-size: 16px;
}
.icon_plus{
    width: 15px;
    height: 15px;
    margin-right: 5px;
}
.icon_plus path{
    fill: #fff;   
}



@media only screen and (max-width: 1230px){
    .address_contacts_delivery_container{
        flex-direction: column;
        margin: 0;
    }
    .card_wrapper{
        width: 100%;
        margin-bottom: 20px;
    }
}