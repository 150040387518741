.maintenance{
    width: 100%;
    height: 100vh;
    background: linear-gradient(90deg, #D3DFF8 0%, #DFF7FF 36.11%, #DFF7FF 61.11%, #D3DFF8 100%);
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
}
.logo{
    margin-top: 50px;
    width: 100%;
    max-width: 180px;
    margin-bottom: 32px;
}
.main_block{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 95%;
    max-width: 400px;
    text-align: center;
    border-radius: 15px;
    background: #FFF;
    padding: 24px;
    box-sizing: border-box;
}
.title{
    color: #0060B7;
    font-size: 24px;
    font-weight: 700;
}
.icon_maintenance{
    margin: 12px 0;
}
.date{
    color: #0060B7;
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 12px;
}
.time{
    color: #0060B7;
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 12px;
}
.text{
    color: #1F85C5;
    font-size: 16px;
    font-weight: 500;
}