.catalog__category{
    border-radius: 20px;
    border: 1px solid #0060B7;
    background-color: #fff;
    margin-bottom: 10px;
    font-size: 16px;
    font-weight: 500;
    color: #0060B7;
    cursor: pointer;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 43px;
}
.catalog__category.active{
    color: #fff;
    background-color: #0060B7;
}
.category_label_quantity{
    border-radius: 50%;
    background-color: #0060B7;
    padding: 5px;
    width: 15px;
    height: 15px;
    color: #fff;
    font-size: 14px;
    font-weight: 500;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 10px;
}
.catalog__category.active .category_label_quantity{
    background-color: #FFF;
    color: #0060B7;
}
@media only screen and (max-width: 1024px){
    .catalog__category{
        width: auto;
        margin: 0 5px 10px 0;
        font-size: 15px;
        min-height: 35px;
        padding: 0 10px;
    }
}
@media only screen and (max-width: 410px){
    .catalog__category{
        width: auto;
        margin: 0 5px 10px 0;
        font-size: 13px;
        min-height: 35px;
        padding: 0 10px;
    }
}