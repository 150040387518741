.btn_container{
    text-align: center;
}
.btn_solid_blue{
    border-radius: 16px;
    background: #0060B7;
    color: #fff;
    padding: 10px 50px;
    font-size: 16px;
    font-weight: 600;
}