.popup__layout{
	position: absolute;
	z-index: 3;

	background: white;

	left: 0; 
    right: 0; 
    margin: 0 auto;
    top: 120px;

	overflow: overlay;
	overscroll-behavior: contain;

	color: #333;
	cursor: initial;

	width: 95%;
    max-width: 500px;
	border-radius: 30px;

	padding: 25px;
	box-sizing: border-box;
}
.header_container{
    display: flex;
    justify-content: space-between;
    margin-bottom: 24px;
}
.header_title{
    font-size: 16px;
    color: #0060B7;
}
.addRelatedProduct__container{
    display: flex;
    flex-direction: column;
    width: 100%;
}
.title{
    color: #0060B7;
    font-size: 18px;
    text-align: center;
    font-weight: 700;
}
.message{
    color: #0060B7;
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 16px;
}
.btn_rules_modal{
    border-radius: 32px;
    border: 2px solid #0060B7;
    color: #0060B7;
    font-size: 16px;
    font-weight: 600;
    margin: 16px 0 8px;
}
/* Radio Buttons */
.itemsContainer,
.radioGroupSelectorContainer{
    box-shadow: 3px -1px 19.5px 0px rgba(0, 96, 183, 0.25);
    border-radius: 16px;
    
}
.itemsContainer{
    padding: 16px 10px 10px 5px;
    margin-bottom: 5px;
    
}
.sublevel_container .itemsContainer{
    width: 100%;
}
.radioGroupSelectorContainer{
    margin: 15px 0;
    padding: 16px;
}
.radioGroupBlock{
    display: flex;
    align-items: center;
    margin-bottom: 16px;
}
.radioGroupItem{
    background-color: white;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 10px;
    padding: 0;
    border: 1px solid #0060B7;
    margin-right: 10px;
}
.radioGroupIndicator{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    position: relative;
}
.radioGroupIndicator:after{
    content: "";
    display: block;
    width: 80%;
    height: 80%;
    border-radius: 50%;
    background-color: #0060B7;
}
.label{
    cursor: pointer;
}

/*  */
.sublevel_container{
    display: none;
    align-items: center;
}
.sublevel_container.active{
    display: flex;
}
.sublevel_svg{
    width: 50px;
    height: 50px;
    margin: 0 10px 10px;
}
.sublevel_svg path{
    fill: #0060B7;
}

/*  */
.btn_block{
    display: flex;
    justify-content: space-around;
    margin: 20px 0 10px;
}
.item_btn_blue_border{
    width: 45%;
    border-radius: 20px;
    border: 1px solid #0060B7;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    color: #0060B7;
    font-size: 16px;
    font-weight: 600;
    padding: 5px 30px;
    outline: none;
}
.item_btn_blue_border:hover{
    background-color: #004D92;
    color: #fff;
}
.item_btn_blue_filled{
    width: 45%;
    border-radius: 20px;
    background-color: #0060B7;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    color: #fff;
    font-size: 16px;
    font-weight: 600;
    padding: 5px 30px;
    outline: none;
}
.item_btn_blue_filled:hover{
    background-color: #004D92;
}

@media only screen and (max-width: 550px){
    .popup__layout{
        height: calc(100% - 70px);
        bottom: 0;
        top: auto;
        width: 100%;
        border-radius: 30px 30px 0 0;
        padding: 25px 10px;
    }
}
