.popup__layout{
	position: absolute;
	z-index: 3;

	background: white;

	left: 0; 
    right: 0; 
    margin: 0 auto;
    top: 120px;

	overflow: overlay;
	overscroll-behavior: contain;

	color: #333;
	cursor: initial;

	width: 95%;
    max-width: 580px;
	border-radius: 30px;

	padding: 25px;
	box-sizing: border-box;
}
.image_container{
    display: flex;
    align-items: center;
    justify-content: center;
}
.image_rule{
    margin: 0 auto;
    max-width: 300px;
}
.btn_container{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 16px;
}

.title{
    color: #0060B7;
    font-size: 24px;
    font-weight: 700;
    text-align: center;
}
.main_text{
    margin: 16px 0;
    color: #000;
    font-size: 16px;
    font-weight: 400;
}
.list{
    list-style-type: disc;
}
.list li{
    margin: 3px 0 3px 25px;
}