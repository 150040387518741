.mainContainer{
    width: 90%;
    max-width: 810px;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.rowContainer{
    display: flex;
    justify-content: space-between;
    width: 100%;
}
.block{
    border-radius: 24px;
    box-sizing: border-box;
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.40) 0.19%, rgba(255, 255, 255, 0.00) 99.8%);
    box-shadow: 3px -1px 19.5px 0px rgba(0, 96, 183, 0.25);
    width: 100%;
    padding: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
}
.header{
    color: #0060B7;
    font-size: 24px;
    font-weight: 600;
    margin: 30px 0 30px;
}
.row{
    display: flex;
    width: 90%;
}
.text_left{
    width: 45%;
}
.text_right{
    width: 55%;
}
.text_default{
    color: #717171;
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 4px;
}
.text_pending{
    color: #B00000;
    font-weight: 700;
}
.text_completed{
    color: #53AC1C;
    font-weight: 700;
}
.text_delivery{
    color: #0060B7;
    font-weight: 700; 
}

/* ORDER NUMBER BLOCK */
.orderNumberBlock{
    border-radius: 16px;
    background: #FFF;
    width: 100%;
    padding: 8px 0 16px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 10px 0;
    
}
.orderNumberTitle{
    color: #000;
    text-align: center;
    font-size: 48px;
    font-weight: 600;
    margin: 10px 0;
}
.orderStatusPending{
    border-radius: 16px;
    border: 2px solid #B00000;
    display: flex;
    padding: 8px 16px;
    justify-content: center;
    align-items: center;
    color: #B00000;
    font-size: 16px;
    font-weight: 600;
}
.orderStatusCompleted{
    border-radius: 16px;
    border: 2px solid #53AC1C;
    display: flex;
    padding: 8px 16px;
    justify-content: center;
    align-items: center;
    color: #53AC1C;
    font-size: 16px;
    font-weight: 600;
}

/* contacts */
.inner_block{
    border-radius: 16px;
    background: #FFF;
    padding: 8px;
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.inner_block .row{
    width: calc(90% + 16px);
    margin: 4px 0;
}
.contact_left{
    width: 45%;
}
.contact_right{
    width: 55%;
}
.contact_label,
.address_label{
    font-size: 16px;
    font-weight: 500;
    color: #000;
}
.contact_text,
.address_text{
    font-size: 16px;
    font-weight: 500;
    color: #0060B7;
}
/* address */
.row_address{
    display: flex;
    width: calc(90% + 16px);
    flex-direction: column;
    margin-bottom: 8px;
}
.address_label{
    margin-bottom: 4px;
}
.address_label,
.address_text{
    width: 100%;
}

.orderDetailsContainer{
    width: 95%;
    max-width: 450px;
}
.orderCartContainer{
    width: 95%;
    max-width: 340px;
}
.cart_title{
    display: flex;
    align-items: center;
    margin: 0 0 15px 15px;
    color: #0060B7;
}
.cart_title h5{
    font-size: 18px;
    font-weight: 600;
}

@media only screen and (max-width: 910px){
    .rowContainer{
        flex-direction: column;
        align-items: center;
    }
    .orderCartContainer{
        max-width: 450px;
    }
}