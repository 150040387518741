/* LOADER */
.loader_container{
    display: flex;
    align-items: center;
    padding: 5px 20px;
}
.loader_spinner{
    border-radius: 50%;
    border-style: solid;
    border-color: rgba(0,0,0,0.3);
    border-top-color: #0060B7;
    animation: 1.5s spin infinite linear;
}
@keyframes spin {
    to {
      transform: rotate(360deg);
    }
}