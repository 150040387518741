.card_wrapper{
    width: 100%;
    margin-bottom: 20px;
}
.section_block{
    display: flex;
    justify-content: space-between;
}
.section_part{
    width: 100%;
    
}
.title_block{
    color: #000;
    font-size: 20px;
    font-weight: 600;
    padding: 8px 0 16px 10px;
}
.card__content{
    box-sizing: border-box;
    padding: 15px 20px;
}
.content_text{
    color: #000;
    font-size: 16px;
    font-weight: 400;
}
.comment_text{
    margin-top: 10px;
}
.btn_change_container{
    display: flex;
    width: 100%;
    justify-content: flex-end;
}
.btn_change_info{
    color: #0060B7;
    font-family: Montserrat;
    font-size: 16px;
    font-weight: 400;
    text-decoration-line: underline;
}
.btn_add_comment{
    background-color: #0060B7;
    border-radius: 10px;
    background: #0060B7;
    padding: 8px 16px;
    color: #fff;
    display: flex;
    align-items: center;
    font-size: 16px;
}
.icon_plus{
    width: 15px;
    height: 15px;
    margin-right: 5px;
}
.icon_plus path{
    fill: #fff;   
}