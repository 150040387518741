/* OUR BENEFITS */
.our_benefits{
    width: 100%;
    margin-top: 150px;
}

.wrapper{
    display: flex;
    width: 80%;
    margin: 0 auto;
}
.wrapper_around{
    width: 100%;
    max-width: 200px;
}
.wrapper_around:not(:last-child) {
    margin-right: 10px;
}

.card__content{
    display: flex;
    flex-direction: column;
    max-height: 420px;
    font-size: 18px;
    font-weight: 500;
}
.benefit_icon{
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background: #1F85C5;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
}
@media only screen and (max-width: 1024px){
    .our_benefits{
        margin: 80px;
    }
    .wrapper{
        width: calc( 100% - 30px );
    }
    .card__content{
        padding: 15px;
    }
}
@media only screen and (max-width: 768px){
    .wrapper{
        flex-wrap: wrap;
        justify-content: center;
    }
    .wrapper_around{
        width: 45%;
        max-width: none;
        margin: 10px;
    }
    
}
@media only screen and (max-width: 500px){
    .wrapper_around{
        margin: 5px;
    }
}