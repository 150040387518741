.wrapper{
    display: flex;
    flex-wrap: wrap;
}
.main_container{
    width: 33.33%;
}
.card__wrapper{
    margin-bottom: 20px;
    width: 95%;
}
.card__content{
    padding: 15px 20px;
    cursor: pointer;
}
.news_icon{
    width: 130px;
    height: 130px;
    border-radius: 15px;
    margin-right: 10px;
    float: left;
}

.header_date_btn_block{
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
}
.date{
    font-size: 14px;
}
.title{
    font-size: 22px;
    text-transform: uppercase;
    font-weight: 900;
    margin-bottom: 20px;
}
.btn_more{
    font-size: 14px;
    color: #1F85C5;
}

/* content */
.content{
    font-size: 16px;
}



@media only screen and (max-width: 1280px){
    .main_container{
        width: 50%;
    }
}
@media only screen and (max-width: 900px){
    .wrapper{
        justify-content: center;
    }
    .main_container{
        width: 80%;
    }
}
@media only screen and (max-width: 650px){
    .main_container{
        width: 100%;
    }
}
@media only screen and (max-width: 480px){
    .title{
        font-size: 18px;
        margin-bottom: 10px;
    }
   
}