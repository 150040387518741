.popup__layout{
	position: absolute;
	z-index: 3;

	border-radius: 24px;
    border: 1px solid #FFF;
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.50) 0%, rgba(255, 255, 255, 0.50) 100%);

	left: 0; 
    right: 0; 
    margin: 0 auto;
    top: 60px;

	overflow: overlay;
	overscroll-behavior: contain;

	color: #333;
	cursor: initial;

	width: 95%;
    max-width: 900px;

	padding: 8px;
	box-sizing: border-box;
}
.container{
    border-radius: 24px;
    background: #FFF;
    padding: 32px 16px;
}
/* NAV */
.nav{
    display: flex;
    margin-bottom: 8px;
}
.title{
    color: var(--TextDefault, #0060B7);
    font-size: 24px;
    font-weight: 700;
    width: 100%;
}
.search{
    padding: 5px 30px;
    margin-right: 55px;
}
.popup_layout__close{
    margin-left: auto;
    outline: none;
    border: none;
    background-color: transparent;
    padding: 20px 25px 10px 15px;
    font-size: 16px;
    cursor: pointer;
    position: absolute;
    top: 10px;
    right: 10px;
}
/* END OF NAV */
.deliveryContainer{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}
.day_block{
    display: flex;
    flex-direction: column;
    width: 31%;
    border-radius: 24px;
    padding: 16px 0;
    box-sizing: border-box;
    margin: 16px 0;
    cursor: pointer;
    box-shadow: 0 0 6px 2px rgba(0, 25, 140, 0.05);
}
.day_block:hover{
    box-shadow: 0 0 6px 11px rgba(0, 25, 140, 0.05);
}
.day_block.active{
    box-shadow: 0 0 6px 11px rgba(0, 25, 140, 0.05);
}
.day_block.inactive{
    filter: grayscale(1);
    opacity: 0.5;
}
.day_title{
    color: #0060B7;
    font-size: 20px;
    font-weight: 600;
    padding-left: 16px;
    margin-bottom: 8px;
}
.list_block{
    list-style-type: none;
    padding-left: 32px;
}
.list_item{
    margin-bottom: 4px;
}
.day_block.active .list_item.active{
    font-weight: 600;
}
.day_block.active .list_item.inactive{
    opacity: 0.5;
}

.btn_container{
    display: flex;
    justify-content: center;
}
@media only screen and (max-width: 650px){
    .day_block{
        width: 49%;
    }
    .nav{
        flex-direction: column;
    }
    .search{
        margin-top: 8px;
    }
}
@media only screen and (max-width: 480px){
    .popup__layout{
        top: 10px;
        margin-bottom: 10px;
    }
    .day_block{
        width: 99%;
    }
}