.title{
    color: var(--TextDefault, #0060B7);
    font-size: 40px;
    font-weight: 600;
    margin-bottom: 32px;
}
.report_block{
    border-radius: 16px;
    background: #FFF;
    box-shadow: 0px 0px 10px 0px rgba(0, 96, 183, 0.25);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 32px;
}
.part_left,
.part_right{
    display: flex;
    align-items: center;
}
.date_label{
    color: #0060B7;
    font-size: 16px;
    font-weight: 500;
    margin-right: 16px;
}
.icon_calendar{
    background-color: #0160b7;
    position: absolute;
    height: 35px;
    top: 0;
    right: 0;
    box-sizing: border-box;
    width: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
}
:global(.react-datepicker-wrapper){
    border-radius: 10px;
    border: 2px solid #0060B7;
    font-size: 16px;
}
:global(.react-datepicker__input-container button){
    font-size: 16px;
    padding: 2px 50px 2px 15px;
    height: 35px;
}