.menu_block{
    border-radius: 16px;
    background: linear-gradient(180deg, #FFF 0%, rgba(255, 255, 255, 0.34) 100%);
    padding: 30px 0 30px 20px;
    box-shadow: 1px 1px 17.8px 0px rgba(189, 189, 218, 0.50);
}
.menu_list{
    
}
.menu_list li:not(:last-child){
    margin-bottom: 24px;
}
.menu_item{
    color: var(--TextDefault, #72a0d4);
    font-size: 16px;
    font-weight: 700;
    cursor: pointer;
}
.menu_item:hover,
.menu_item.active{
    color: #0060B7;
}
.icon{
    margin-left: 5px;
}