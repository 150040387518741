.cart_block{
    width: 100%;
    display: flex;
    flex-direction: column;
    min-height: 90px;
    border-bottom: 1px solid #E6E6E6;
    margin-bottom: 10px;
    /* max-height: 145px; */
}
.sub_product{
    margin: 10px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.sublevel_svg{
    width: 17%;
    height: 40px;
    margin: 0 0 0 10px;
}
.sublevel_svg path{
    fill: #0060B7;
}