/****************************************************************************************/
/**************************************** burger ****************************************/
/****************************************************************************************/
:global{
    #root{
        background-color: #000;
    }
}

.nav{
    width: calc( 100% - 60px );
    max-width: 1380px;
    height: 75px;
    position: fixed;
    top: 25px;
    box-sizing: border-box;
    padding: 0 30px;
    border-radius: 30px;
    background: linear-gradient(180deg, #FFF 0%, rgba(255, 255, 255, 0.34) 100%);
    box-shadow: 0px 2px 19.8px 0px rgba(26, 31, 36, 0.10);
    
    backdrop-filter: blur(25px);
    
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.nav__left{

}
.nav__right{
    display: flex;
    align-items: center;
    justify-content: center;
}
.user_info_block{
    margin-right: 32px;
    text-align: center;
}
.user_info_fullName{
    color: #000;
    font-size: 16px;
    font-weight: 600;
}
.user_info_role{
    color: #000;
    font-size: 12px;
    font-weight: 400;
}

.logout{
    color: #0060B7;
    font-size: 16px;
    font-weight: 600;
    border-radius: 10px;
    border: 3px solid #0060B7;
    padding: 10px 16px;
    box-sizing: border-box;
    cursor: pointer;
}

/****************************************************************************************/
/**************************************** left   ****************************************/
/****************************************************************************************/

/****************************************************************************************/
/**************************************** right  ****************************************/
/****************************************************************************************/

.nav__btn{
    padding: 0 20px;
    display: flex;
    align-items: center;
    border-radius: 20px;
    border: 2px solid rgba(0, 96, 183, 0.10);
    background-color: #0060B7;
    height: 32px;
    font-size: 16px;

    color: #fff;
    margin-right: 10px;
    font-weight: 700;
    font-family: inherit;

    -webkit-transition: all 0.3s, color 0.3s;
	  transition: all 0.3s, color 0.3s;
}
.nav__btn:hover{
  background-color: #fff;
  color: #0060B7;
  border: 2px solid #0060B7;

}

.nav__btn_click{
  height: 35px;
}
.nav__shopping_cart__btn{
    padding: 5px 8px 5px 38px;
    text-align: center;
    border-radius: 20px;
    border: 2px solid rgba(0, 96, 183, 0.10);
    background: #0060B7;

    display: flex;
    align-items: center;
    margin: 0 20px 0 10px;
    justify-content: flex-end;
    position: relative;
}
.nav__shopping_cart__label{
    border-radius: 30px;
    color: #0060B7;
    background-color: #fff;
    position: absolute;
    left: 0;
    height: 30px;
    width: 30px;
    font-weight: 700;

    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    font-family: inherit;
}
.nav_btn_mobile{
  margin: 0 30px 0 10px;
  padding: 0 20px;
}

/****************************************************************************************/
/**************************************** burger ****************************************/
/****************************************************************************************/

#menuToggle
{
  position: relative;  
  z-index: 1;
  
  -webkit-user-select: none;
  user-select: none;
}

#menuToggle a,
#menuToggle button{
  text-decoration: none;
  color: #69a3d9;
  font-size: 24px;
  font-weight: 600;
  padding: 0;
  transition: color 0.3s ease;
}

#menuToggle a:hover{
  color: #0060B7;
}

#menuToggle a.active,
#menuToggle button.active{
  color: #0060B7;
}


#menuToggle input
{
  display: block;
  width: 40px;
  height: 32px;
  position: absolute;
  top: -7px;
  left: -5px;
  
  cursor: pointer;
  
  opacity: 0; /* hide this */
  z-index: 5; /* and place it over the hamburger */
  
  -webkit-touch-callout: none;
}

/*
 * Just a quick hamburger
 */
#menuToggle span
{
  display: block;
  width: 33px;
  height: 3px;
  margin: 5px 0;
  position: relative;
  
  background: #0060B7;
  border-radius: 3px;
  
  z-index: 4;
  
  transform-origin: 4px 0px;
  
  transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
              background 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
              opacity 0.55s ease;
}

#menuToggle span:first-child
{
  transform-origin: 0% 0%;
}

#menuToggle span:nth-last-child(2)
{
  transform-origin: 0% 100%;
}

/* 
 * Transform all the slices of hamburger
 * into a crossmark.
 */
#menuToggle input:checked ~ span
{
  opacity: 1;
  transform: rotate(45deg) translate(-2px, -1px);
  background: #0060B7;
}

/*
 * But let's hide the middle one.
 */
#menuToggle input:checked ~ span:nth-last-child(3)
{
  opacity: 0;
  transform: rotate(0deg) scale(0.2, 0.2);
}

/*
 * Ohyeah and the last one should go the other direction
 */
#menuToggle input:checked ~ span:nth-last-child(2)
{
  transform: rotate(-45deg) translate(0, 1px);
}

/*
 * Make this absolute positioned
 * at the top left of the screen
 */
#menu{
    position: absolute;
    border-radius: 30px;
    width: 500px;
    margin: -52px 0 0 0;
    padding: 50px;
    padding-top: 25px;
    box-sizing: border-box;
    right: -30px;
    background: #fff;
    list-style-type: none;
    -webkit-font-smoothing: antialiased;
    /* to stop flickering of text in safari */
    transform-origin: 0% 0%;
    transform: translate(150%, 0%);
    transition: opacity 0.4s cubic-bezier(0.77,0.2,0.05,1.0), transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0);
    overflow: hidden;
    box-shadow: 0px 2px 19.8px 0px rgba(26, 31, 36, 0.20);
    opacity: 0;
    z-index: 3;
}

#menu:before{
  content: "";
  background-image: url('../../visitor/assets/img/by_page/menu/bottle.png');
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 15%;
  right: -60%;
  background-size: 80%;
  opacity: 0.5;
}
#menuToggle input:checked ~ div #menu{
  opacity: 1;
}
#menu .menu_open_logo{
  margin-bottom: 20px;
  width: 120px;
}
#menu li{
  padding: 10px 0;
}

/*
 * And let's slide it in from the right
 */
#menuToggle input:checked ~div ul{
  transform: none;
}
.backdrop{
  width: 100%;
  height: 100%;
  z-index: 2;
  top: 0;
  left: 0;
  position: fixed;
  display: none;
}
#menuToggle input:checked ~ div .backdrop{
  display: flex;
}
.nav__item__wrapper{
  display: flex;
  align-items: center;
  height: 40px;
}
.nav__item__icon{
  margin-right: 10px;
  opacity: 0;
}
.nav__item__wrapper:hover .nav__item__icon,
.active .nav__item__icon{
  margin-right: 10px;
  opacity: 1; 
}

@media only screen and (max-width: 690px){
  .nav__left{
    display: none;
  }
  .nav__right{
    width: 100%;
    justify-content: space-between;
  }
}

@media only screen and (max-width: 585px){
  .nav{
    width: 100%;
  }
  #menu{
    width: 400px;
  }
  #menuToggle a,
  #menuToggle button{
    font-size: 18px;
  }
  #menu li{
    padding: 8px 0;
  }
  .nav__item__wrapper{
    font-size: 22px;
  }
  
}
/* STANDALONE APP */
@media only screen and (max-width: 550px){
  .nav{
    padding: 10px 20px 0 20px;
    top: 0;
    height: 60px;
    border-radius: 0 0 30px 30px;
    background: #edf3f8;
    box-shadow: 0px 2px 19.8px 0px rgba(26, 31, 36, 0.30);
  }
  #menu{
    margin: -48px 0 0 0;
    padding-top: 25px;
    box-shadow: -4px 12px 20px 0 rgba(26, 31, 36, 0.3);
  }
}
/* END OF STANDALONE APP */

@media only screen and (max-width: 525px){
  /* .nav{
    height: 60px;
    padding: 20px;
  } */
  .nav__btn{
    margin-right: 5px;
  }
  .nav__shopping_cart__btn{
    margin: 0;
  }
  .nav_btn_mobile{
    margin: 0;
  }
}

@media only screen and (max-width: 500px){
  .nav__btn{
    padding: 0 15px;
  }
}

@media only screen and (max-width: 450px){
  #menu{
    width: 350px;
    padding: 25px 0 30px 45px;
  }
  .nav__btn{
    font-size: 14px;
    padding: 0 10px;
  }
  .nav__shopping_cart__btn{
    padding: 5px 3px 5px 33px;
  }
  .nav__btn_click svg{
    height: 20px;
  }
}