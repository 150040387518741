.login_block{
    width: calc(100% - 20px);
    max-width: 700px;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.title{
    margin: 32px 0 32px;
    color: #0060B7;
    font-size: 28px;
    font-weight: 600;
}

.logo{
    width: 70%;
    max-height: 70px;
}
.formLogin{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 24px;

    border-radius: 32px;
    border: 1.25px solid #FFF;
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.40) 0.19%, rgba(255, 255, 255, 0.00) 99.8%);
    box-shadow: 3.75px -1.25px 24.375px 0px rgba(0, 96, 183, 0.25);
    backdrop-filter: blur(6.25px);
    width: 100%;
    box-sizing: border-box;
    margin: 70px 0 0 0;
}
.form_loginFieldsBlock{
    margin: 16px 0 32px 0;
    width: 100%;
}
.form_loginFields_row{
    width: 100%;
    display: flex;
    justify-content: center;
}
.form_loginFields_row:first-child{
    margin-bottom: 16px;
}
.form_inputField{
    width: 80%;
    max-width: 480px;
    font-size: 16px;
}

@media only screen and (max-width: 550px){
    .title{
        margin: 0 0 16px;
        font-size: 18px;
    }
    .logo{
        max-height: 50px;
    }
    .formLogin{
        margin: -20px 0 0 0;
        height: calc( 100vh - 100px);
    }
}