.news{
    width: 100%;
    margin-top: 150px;
}

.wrapper{
    display: flex;
    justify-content: space-between;
    width: calc( 100% - 120px );
    margin: 0 auto;
}

.card__wrapper{
    width: 90%;
    cursor: pointer;
}
.card__content{
    border-radius: 30px 30px 0 0;
}
.see_more_container{
    display: flex;
    align-items: center;
    cursor: pointer;
}
.see_more_btn{
    color: #1F85C5;
    font-size: 14px;
    font-weight: 500;
}

.news_title{
    font-size: 22px;
    font-weight: 600;
    margin: 10px 0;
    text-transform: uppercase;
}
.news_img_container{
    border-radius: 0 0 30px 30px;
    height: 160px;
    overflow: hidden;
}
.news_img_container img{
    width: 100%;
}
:global(.newsSwiper.swiper .swiper-wrapper){
    margin-bottom: 15px;
  }
/* modal */
.news_header{
    display: flex;
    justify-content: space-between;
}
@media only screen and (max-width: 1024px){
    .news{
        margin-top: 80px;
    }
}
@media only screen and (max-width: 600px){
    .wrapper{
        width: calc(100% - 60px);
    }
}
@media only screen and (max-width: 500px){
    .wrapper{
        width: calc(100% - 20px);
    }
}
@media only screen and (max-width: 450px){
    .wrapper{
        width: 100%;
    }
}