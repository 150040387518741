.wrapper{
    display: flex;
    width: calc(100% - 60px);
}
.card__wrapper{
    width: 80%;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
}
.card__content_1{
    width: 70%;
    box-sizing: border-box;
    line-height: 1.5;
}
.about_water_img{
    width: 29%;
}
.card__content_2{
    box-sizing: border-box;
    margin-top: 15px;
    width: 100%;
    line-height: 1.5;
}
@media only screen and (max-width: 1280px){
    .card__wrapper{
        width: 100%;
    }
    .card__content_1{
        width: 60%;
    } 
    .about_water_img{
        width: 39%;
    }
}
@media only screen and (max-width: 1000px){
    .card__wrapper{
        flex-direction: column;
        align-items: center;
        flex-wrap: initial;
    }
    .card__content_1{
        width: 100%;
    } 
    .about_water_img{
        width: 80%;
        margin-top: 15px;
    }
}
@media only screen and (max-width: 550px){
    .wrapper{
        width: calc(100% - 30px);
    }
}
