.wrapper{
    display: flex;
    width: calc(100% - 60px);
    flex-wrap: wrap;
    margin-bottom: 40px;
}
.card__wrapper{
    width: 100%;
    margin-bottom: 20px;
}
.card{
    margin-bottom: 20px;
}
.title{
    font-size: 26px;
    margin-bottom: 10px;
}

@media only screen and (max-width: 550px){
    .wrapper{
        width: calc(100% - 30px);
    }
}