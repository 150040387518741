.tab_content span{
    margin-right: 10px;
}
.tab_content svg path{
    stroke: #000;
}
:global(.react-tabs__tab--selected) .tab_content svg path{
    stroke: #fff;
}
.tabs{
    display: flex;
    justify-content: space-between;
    width: 80%;
    border-radius: 24px;
    background-color: #FFF;
    padding: 30px 25px;
    margin: 10px auto 40px;
}

.tabs .left{
    width: 45%;
    margin: 0;
}

.tab_item{
    border-bottom: 1px solid #0060B7;
    display: flex;
    outline: none;
}
.tab_content{
    border-radius: 16px;
    padding: 8px 16px;
    margin: 8px 0;
    font-weight: 500;
    font-size: 16px;
    width: 100%;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

:global(.react-tabs__tab--selected) .tab_content{
    background: #0060B7;
    color: #fff;
}
.tabs .right{
    width: 50%;
}
.certTitle{
    text-align: center;
    color: #0060B7;
    font-size: 16px;
    font-weight: 500;
    margin: 8px 0 24px;
}
.cert_block{
    display: flex;
    justify-content: space-around;
}
.cert_content{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.language{
    font-weight: 500;
    font-size: 18px;
    margin: 16px 0 11px;
}
.btn_blue{
    display: flex;
    border-radius: 15px;
    background: #0060B7;
    padding: 4px 16px;
    align-items: center;
    color: #fff;
    margin: 5px 0;
    font-size: 14px;
}
.btn_blue img{
    margin-right: 10px;
}
.cert_img{
    width: 70%;
}
.qr{
    width: 50%;
}